<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-dialog title="" :visible.sync="dialogVisible_detail" custom-class="cus_dialog cus_dialogFullHeight" width="90%"
      :close-on-click-modal="false" @close="onClose">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnSingleCenter" style="height:100%;width: 40%;">
          <div class="boxShadow flexCloumnSingleCenter" style="height: 60%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width:100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="90px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">{{ EditItem.username }}
                </el-form-item>

                <el-form-item label="所在部门:">{{ EditItem.dept_name }}</el-form-item>

                <el-form-item label="申请时间:">{{ EditItem.cdate }}</el-form-item>

                <el-form-item label="申购单号:" prop="buy_type">{{ EditItem.sg_num }}</el-form-item>


                <el-form-item label="购买预算:" prop="title">{{ EditItem.howmuch || '-' }}
                </el-form-item>

                <el-form-item label="购买原因:" prop="remark">
                  <el-input type="textarea" :rows="5" :value="EditItem.remark||'-'" size="mini" disabled></el-input>
                </el-form-item>
                <el-form-item label="图片:">
                  <div class="flexStart">

                    <div
                      v-if="EditItem.files && EditItem.files.length > 0 && EditItem.files != [] && EditItem.files!='[]'"
                      class="updimage" v-for="(f, idx) in EditItem.files"
                      :style="{ backgroundImage: 'url(' + f.src + ')' }" style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;cursor:pointer;" />
                    </div>

                    <div
                      v-if="!EditItem.files || EditItem.files.length == 0 || EditItem.files == [] || EditItem.files == '[]'"
                      class="">-</div>
                  </div>
                </el-form-item>

              </el-form>
            </div>
          </div>


          <div class="shadow flexCloumn mt10" style="height:  calc( 40% - 10px);width: 100%;">
            <div class="titleBar flexStart">
              <div class="div flexStart">流程状态</div>
            </div>
            <processview v-if="EditItem.id" ref="processview" :process_code="process_code" table_name="zichan_sg"
              :form_id="EditItem.id" class="flex_1" style="overflow-y: auto;">
            </processview>
          </div>
        </div>
        <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: calc( 60% - 10px);">
          <el-table ref="mySGtable" :data="EditItem.list" size="mini" height="100%" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" label="序号" width="100px" align="center" />
            <!--                <el-table-column prop="title" label="耗材名称/品牌/型号/分类" width="200" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <div class="flexStart">
                      <div style="color: #409EFF;">{{ scope.row.title }}</div>&nbsp;
                      <div>{{ scope.row.pinpai }}</div>&nbsp;
                      <div>{{ scope.row.xinghao }}</div>&nbsp;

                    </div>
                    <div class="flexStart" style="color: #909399;">分类：{{ scope.row.typename1 }}</div>
                  </template>
                </el-table-column>-->
            <el-table-column prop="title" label="耗材名称" show-overflow-tooltip width="200px" align="center">
              <template slot="header">
                <span style="color: #F56C6C">耗材名称</span>
              </template>
            </el-table-column>
            <el-table-column prop="pinpai" label="品牌" width="100px" align="center">
              <template slot="header">
                <span style="color: #F56C6C">品牌</span>
              </template>
            </el-table-column>
            <el-table-column prop="xinghao" label="型号" width="100px" align="center">
              <template slot="header">
                <span style="color: #F56C6C">型号</span>
              </template>
            </el-table-column>
            <!--                <el-table-column prop="typename1" label="分类"  width="100" align="center">
                  <template slot="header">
                    <span style="color: #F56C6C">分类</span>
                  </template>
                </el-table-column>-->
            <el-table-column prop="storename" label="存放地" min-width="100px" align="center">
              <template slot="header">
                <span style="color: #F56C6C">存放地</span>
              </template>
            </el-table-column>

            <el-table-column prop="cgJine" width="120px" :show-overflow-tooltip="true" align="center" :formatter="formatter">
              <template slot="header">
                <span style="color: #F56C6C">采购单价(元)</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{ formatter(null,null,scope.row.cgJine) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="cgNum" width="100px" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">采购数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{ scope.row.cgNum }}{{ scope.row.jldw }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="cgTotal" label="采购总价(元)" :show-overflow-tooltip="true" width="120px"
              align="center" :formatter="formatter" />

            <el-table-column prop="img" label="图片" width="120px" align="center">
              <template slot-scope="scope">

                <div class="flexStart">
                  <div v-if="scope.row.img" class="updimage"
                    style="position: relative;margin-top:10px;width: 100px;height: 100px;">
                    <img :src="scope.row.img" @click="handlePictureCardPreview(scope.row.img)"
                      style="width: 100px;height: 100px;cursor:pointer;">
                  </div>

                  <div v-if="!scope.row.img||scope.row.img==''">-</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="externalLinks" label="链接" width="200px" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.links && scope.row.links.length > 0" v-for="(item, index) in scope.row.links">
                  <el-tooltip :enterable="false" :disabled="!item||(item&&item.length<28)" class="item" effect="dark"
                    placement="top-start">
                    <div slot="content" style="width: 400px;">{{ item }}</div>
                    <el-link :href="item" target="_blank" type="primary">
                      <div class="overOneLine" style="width: 180px;">{{ item }}</div>
                    </el-link>
                  </el-tooltip>
                </div>
                <div v-if="!scope.row.links||scope.row.links.length==0">-</div>
              </template>
            </el-table-column>
            <el-table-column prop="user_remark" label="备注" width="200px" :show-overflow-tooltip="true" align="center" />
          </el-table>
        </div>
      </div>


      <div slot="footer">
        <!--          <span v-if="EditItem.sp_status == 2" style="white-space: nowrap;">驳回原因：</span>-->
        <!--          <el-input v-if="EditItem.sp_status == 2" v-model="EditItem.spword" size="small" disabled-->
        <!--                    style="margin-right: 20px;"></el-input>-->
        <el-button type="primary" @click="onClose" plain size="small">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import processview from "../../../com/processviewmini.vue"

export default {
  components: {
    processview,
  },
  data() {
    return {
      //--审批流程用----
      process_code: "hcsg",
      process_id: 0,
      needChoose: false,//是否需要选择下级
      choosedUser: [],//已选择的用户
      //--审批流程用----
      disabled: false,
      files: [],
      arr_json: [],
      EditItem: {},
      
    }
  },
  props: ['dialogVisible_detail', 'dialogTitle', 'EditItem1'],
  mounted() {
    console.log(174, this.EditItem1)
    this.getEditItem()
  },
  methods: {
    formatter(row,col,cellValue, index){
      return Number(cellValue).toFixed(2)
    },
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----
    getEditItem() {
      let _this = this
      this.$http.post("/api/zichan_sg_one", {id: this.EditItem1.id}).then(res => {
        let resData = res.data
        resData.listLen = 0
        if (resData.hc_list) {
          resData.list = JSON.parse(resData.hc_list)
          resData.listLen = resData.list.length
          resData.desc = `${resData.dept_name} ${resData.username} 申购 ${resData.listLen}件物品`
          resData.list.map(a=>{
            let links = []
            a.links = []
            if (a.externalLinks) {
              if (a.externalLinks.indexOf(';') > 0) {
                links = a.externalLinks.split(';')
                a.links = links
              }else if (a.externalLinks.indexOf('；') > 0) {
                links = a.externalLinks.split('；')
                a.links = links
              }else {
                a.links = [a.externalLinks]
              }
            }

          })
        }
        let files = resData.files?JSON.parse(resData.files):[]
        for (let item of files) {
          item.fileName = item.fileName.substr(item.fileName.lastIndexOf('/') + 1)
        }
        resData.files = files;
        this.EditItem = resData
      })
    },
    handlePictureCardPreview(src) {
      console.log(this.$parent)
      this.$$parent(this, 'handlePictureCardPreview', src)
    },
    onClose() {
      this.$$parent(this, 'dialogVisible_detail', false)
    }
  }
}
</script>


